import React, { createRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EmojiSymbolsIcon from '@mui/icons-material/EmojiSymbols';

const chars = {
  A: new Uint8Array([0x70, 0xD6, 0x01, 0x00]),
  B: new Uint8Array([0x71, 0xD6, 0x01, 0x00]),
  C: new Uint8Array([0x72, 0xD6, 0x01, 0x00]),
  D: new Uint8Array([0x73, 0xD6, 0x01, 0x00]),
  E: new Uint8Array([0x74, 0xD6, 0x01, 0x00]),
  F: new Uint8Array([0x75, 0xD6, 0x01, 0x00]),
  G: new Uint8Array([0x76, 0xD6, 0x01, 0x00]),
  H: new Uint8Array([0x77, 0xD6, 0x01, 0x00]),
  I: new Uint8Array([0x78, 0xD6, 0x01, 0x00]),
  J: new Uint8Array([0x79, 0xD6, 0x01, 0x00]),
  K: new Uint8Array([0x7A, 0xD6, 0x01, 0x00]),
  L: new Uint8Array([0x7B, 0xD6, 0x01, 0x00]),
  M: new Uint8Array([0x7C, 0xD6, 0x01, 0x00]),
  N: new Uint8Array([0x7D, 0xD6, 0x01, 0x00]),
  O: new Uint8Array([0x7E, 0xD6, 0x01, 0x00]),
  P: new Uint8Array([0x7F, 0xD6, 0x01, 0x00]),
  Q: new Uint8Array([0x80, 0xD6, 0x01, 0x00]),
  R: new Uint8Array([0x81, 0xD6, 0x01, 0x00]),
  S: new Uint8Array([0x82, 0xD6, 0x01, 0x00]),
  T: new Uint8Array([0x83, 0xD6, 0x01, 0x00]),
  U: new Uint8Array([0x84, 0xD6, 0x01, 0x00]),
  V: new Uint8Array([0x85, 0xD6, 0x01, 0x00]),
  W: new Uint8Array([0x86, 0xD6, 0x01, 0x00]),
  X: new Uint8Array([0x87, 0xD6, 0x01, 0x00]),
  Y: new Uint8Array([0x88, 0xD6, 0x01, 0x00]),
  Z: new Uint8Array([0x89, 0xD6, 0x01, 0x00]),
  a: new Uint8Array([0x8A, 0xD6, 0x01, 0x00]),
  b: new Uint8Array([0x8B, 0xD6, 0x01, 0x00]),
  c: new Uint8Array([0x8C, 0xD6, 0x01, 0x00]),
  d: new Uint8Array([0x8D, 0xD6, 0x01, 0x00]),
  e: new Uint8Array([0x8E, 0xD6, 0x01, 0x00]),
  f: new Uint8Array([0x8F, 0xD6, 0x01, 0x00]),
  g: new Uint8Array([0x90, 0xD6, 0x01, 0x00]),
  h: new Uint8Array([0x91, 0xD6, 0x01, 0x00]),
  i: new Uint8Array([0x92, 0xD6, 0x01, 0x00]),
  j: new Uint8Array([0x93, 0xD6, 0x01, 0x00]),
  k: new Uint8Array([0x94, 0xD6, 0x01, 0x00]),
  l: new Uint8Array([0x95, 0xD6, 0x01, 0x00]),
  m: new Uint8Array([0x96, 0xD6, 0x01, 0x00]),
  n: new Uint8Array([0x97, 0xD6, 0x01, 0x00]),
  o: new Uint8Array([0x98, 0xD6, 0x01, 0x00]),
  p: new Uint8Array([0x99, 0xD6, 0x01, 0x00]),
  q: new Uint8Array([0x9A, 0xD6, 0x01, 0x00]),
  r: new Uint8Array([0x9B, 0xD6, 0x01, 0x00]),
  s: new Uint8Array([0x9C, 0xD6, 0x01, 0x00]),
  t: new Uint8Array([0x9D, 0xD6, 0x01, 0x00]),
  u: new Uint8Array([0x9E, 0xD6, 0x01, 0x00]),
  v: new Uint8Array([0x9F, 0xD6, 0x01, 0x00]),
  w: new Uint8Array([0xA0, 0xD6, 0x01, 0x00]),
  x: new Uint8Array([0xA1, 0xD6, 0x01, 0x00]),
  y: new Uint8Array([0xA2, 0xD6, 0x01, 0x00]),
  z: new Uint8Array([0xA3, 0xD6, 0x01, 0x00]),
};

const utf32Decode = bytes => {
  const view = new DataView(bytes.buffer, bytes.byteOffset, bytes.byteLength);
  let result = '';

  for (let i = 0; i < bytes.length; i += 4) {
    result += String.fromCodePoint(view.getInt32(i, true));
  }

  return result;
};

export default function Game(props) {
  const games = [
    /**
     * Single girl game
     */
    {
      name: 'Single girl',
      topScore: 'Bella 80k',
      stages: [
        { // Points game
          name: 'Logo',
          title: 'Points game',
          background: '/images/backgrounds/01.png',
          dares: [
            {'dare': ''},
            {'dare': 'Do you feel naughty and horny?'},
            {'dare': 'Are you looking for some real fun?'},
            {'dare': ''},
            {'dare': 'Give me a heart or type START'},
          ],
          messages: [
            'hey',
            'hey there!',
            'hey sexy!',
            'how are you?',
            'are you even real?',
            'kinda bored until now :)',
            'are you looking for some real fun?',
            'would you prefer to play the game or just take orders? :D',
            'huh, it says you may be a simulated cam',
            'like being told what to do?',
            'I\'ve got the perfect game for you :) wanna play?',
            'If you can\'t see the dares, tell me and i\'ll type them for you in the chat!',
            'why did you leave the last time you played? I missed you :)',
            'do you have any extra lights in there?',
          ],
        },
        { // Hello stage
          name: 'Hello',
          title: 'Hello stage',
          background: '/images/backgrounds/02.png',
          dares: [
            {'dare': 'Type your age'  , points: 10},
            {'dare': 'Wave your hand' , points: 20},
            {'dare': 'Type your name' , points: 30},
            {'dare': 'Give me a smile', points: 40},
            {'dare': 'Bite your lips' , points: 50},
          ],
          messages: [
            'hey',
            'hey there!',
            'hey sexy!',
            'do you have any extra lights in there please?',
            'wanna skip the face ones?',
            'fair enough :)',
            'if you want you can hide your face but please don\'t cover the cam anymore, ok?',
            'damn, cute smile! <3',
          ],
        },
        { // Level 1
          name: 'L1',
          title: 'Level 1',
          background: '/images/backgrounds/03.png',
          dares: [
            {'dare': 'Suck one finger'    , points: 50},
            {'dare': 'Suck two fingers'   , points: 60},
            {'dare': 'Lick your lips'     , points: 70},
            {'dare': 'Show boobs cleavage', points: 80},
            {'dare': 'Show stomach'       , points: 90},
          ],
          messages: [
            'actually a killing smile :)',
            'mmmmmmmmm, lovely sensual lips...',
            'you really love to suck on them, don\'t you? :D',
            'the cleavage is the area between your boobs when you squeeze them together',
            'wow!!! unreal hot boobs baby!',
            'such a sexy view baby',
          ],
        },
        { // Level 2
          name: 'L2',
          title: 'Level 2',
          background: '/images/backgrounds/04.png',
          dares: [
            {'dare': 'Lift up your top and show bra or boobs'       , points: 250},
            {'dare': ''},
            {'dare': 'Pull off your pants and show panties or kitty', points: 250},
          ],
          messages: [
            'omg, those boobs must be out of this world!',
            'mmmmmmmmm, matching outfit! sexy as fuck!',
            'damn, sexy panties!',
            'fuck baby, you have such a gorgeous sexy body!!! a real sex goddess on earth!',
          ],
        },
        { // Flash stage
          name: 'Flash',
          title: 'Flash stage',
          background: '/images/backgrounds/05.png',
          dares: [
            {'dare': 'Take off your top'  , points: 250},
            {'dare': 'Flash your boobs'   , points: 250},
            {'dare': ''},
            {'dare': 'Take off your pants', points: 250},
            {'dare': 'Flash your pussy'   , points: 250},
          ],
          messages: [
            'well, is your door locked? :D',
            'smart girl :)',
            'you little naughty girl :)',
            'what about to sneak in the bathroom then? I bet that you\'ll feel more comfy in there',
            'damn, a hairy natural pussy is the best of the best!',
            'you\'ll have to take them off and keep them off tho :)',
            'wow!!! unreal hot boobs baby!',
            'omg, those boobs must be out of this world!',
          ],
        },
        { // Level 3
          name: 'L3',
          title: 'Level 3',
          background: '/images/backgrounds/06.png',
          dares: [
            {'dare': 'Take off your bra', points: 1000},
            {'dare': 'Bounce boobs'     , points:  500},
            {'dare': 'Play with boobs'  , points:  500},
            {'dare': 'Play with nipples', points:  500},
            {'dare': 'Jiggle boobs'     , points:  500},
          ],
          messages: [
            'please baby, do you have any extra lights in there?',
            'wow, just look at those gorgeous perky boobs!',
            'just bounce them from side to side :)',
            'and those cute puffy nips are sooo fucking suckable baby!!!',
          ],
        },
        { // Doggy stage
          name: 'Doggy',
          title: 'Doggy stage',
          background: '/images/backgrounds/07.png',
          dares: [
            {'dare': 'Doggy style - ass toward cam'                       , points: 1000},
            {'dare': 'Pull panties tight against ass'                     , points: 1000},
            {'dare': 'Slowly lower the panties'                           , points: 1000},
            {'dare': 'Shake / twerk / slap ass'                           , points: 1000},
            {'dare': 'BONUS: Play with your pussy and moan while in doggy', points: 1000},
          ],
          messages: [
            'some panties on would help you complete this stage',
            'doggy means to stay in your fours (limbs) with ass on cam :)',
            'what a fucking beautiful sexy ass!',
            'damn, probably you have the best ass around EVER!',
            'that\'s not enough :) slap them harder!',
            'baby, grab those sexy cheeks and pull them aside',
          ],
        },
        { // Level 4
          name: 'L4',
          title: 'Level 4',
          background: '/images/backgrounds/08.png',
          dares: [
            {'dare': 'Take off your panties'                      , points: 1000},
            {'dare': 'Clit close-up'                              , points:  500},
            {'dare': 'Spread your pussy'                          , points:  500},
            {'dare': 'Are you still a virgin?'                    , points:  500},
            {'dare': 'Get & remain naked for the rest of the game', points:  500},
          ],
          messages: [
            'the clit is your pussy\s "nose" :)',
            'oh my, just open that clit\'s hood!',
            'why don\t you try to get that phone against something so that it will stay alone? you\'ll get an extra free hand :)',
            'omfg, you have such a fucking perfect hot body baby!!!',
            'you\'re such a fucking sex goddess <3',
            'baby, wouldn\'t you feel more comfortable if you\'d move in bed for the rest of the game?',
            'baby, wouldn\'t you feel more comfortable if you\'d move on the floor for the rest of the game?',
          ],
        },
        { // Ass stage
          name: 'Ass',
          title: 'Ass stage',
          background: '/images/backgrounds/09.png',
          dares: [
            {'dare': 'Bend over / doggy position'                                                     , points: 1000},
            {'dare': 'Arch your ass up while in doggy and spread ass cheeks and pussy with both hands', points: 1000},
            {'dare': 'BONUS: Watch at cam while spreading wide ass cheeks'                            , points: 2000},
          ],
          messages: [
            'you may skip the last dare if you want to. I respect your privacy!',
            'I just love your gorgeous sexy ass baby',
            'def the best ass ever!',
            'grab those sexy cheeks and spread that hot ass wider',
          ],
        },
        { // Boobs stage
          name: 'Boobs',
          title: 'Boobs stage',
          background: '/images/backgrounds/10.png',
          dares: [
            {'dare': 'Show boobs (close)'                                       , points: 500 },
            {'dare': 'Squeeze boobs together'                                   , points: 500},
            {'dare': 'Spit & lick nipples'                                      , points: 1000},
            {'dare': 'Pinch your nipples'                                       , points: 1000},
            {'dare': 'BONUS: Jiggle boobs while pinching & pulling your nipples', points: 2000},
          ],
          messages: [
            'holly molly, those sexy boobs are killing me!',
            'show me how dirty you like to be with those hot boobs baby...',
            'mmmmm, love how dirty can you be!',
            'pinch them even harder!'
          ],
        },
        { // Pussy stage
          name: 'Pussy',
          title: 'Pussy stage',
          background: '/images/backgrounds/11.png',
          dares: [
            {'dare': 'Lay on your back and show pussy close-up', points: 1000},
            {'dare': 'One finger in pussy'                     , points: 1000},
            {'dare': 'Two fingers in pussy'                    , points: 1000},
            {'dare': 'Spread pussy lips'                       , points: 1000},
            {'dare': 'Masturbate & moan'                       , points: 1000},
          ],
          messages: [
            'get that sweet pussy closer please',
            'mmmmmmmmmmm, that sweet pussy looks sooo fucking wet and horny!',
            'just love how wet sounds your pussy!!!',
            'how many fingers can you take in there? :D',
            'no fucking way!!! are you kidding me? :O',
            'I bet that someday you\'ll be able to fist yourself!',
            'mmmmmmm, that must feel soooo fucking good rn!',
            'that\'s a really happy and lucky pussy baby! <3',
            'hold your leg back and rub that sweet clit',
          ],
        },
        { // Bonus stage
          name: 'Bonus',
          title: 'Bonus stage',
          background: '/images/backgrounds/12.png',
          dares: [
            {'dare': 'Leaning on your back, put one finger from each hand in your pussy and use them like some hooks to spread it as wide as you can!', points: 2000},
            {'dare': ''},
            {'dare': 'BONUS: Type your real age'                                                                                                      , points: 2000},
          ],
          messages: [
            'get that sweet pussy closer',
            'fuck baby, you have such a beautiful rose down there!',
            'do it again please, even deeper and wider this time please!',
            'baby, wanna do it again using two fingers from each hand this time?',
          ],
        },
        { // Kinks stage
          name: 'Kinks',
          title: 'Kinks stage',
          background: '/images/backgrounds/13.png',
          dares: [
            {'dare': 'Are you into kinky dirty filthy things?'                  , points: 1000},
            {'dare': 'What was the kinkiest thing you ever did with your pussy?', points: 1000},
            {'dare': 'Taste your own pussy juice'                               , points: 1000},
            {'dare': 'Grab a toy or an object'                                  , points: 1000},
          ],
          messages: [
            'hehe, that\'s hot as fuck but not kinky at all :))',
            'what about to dare you something really kinky? i bet that you\'ll love it!',
            'just don\'t get too scared please :)',
            'do you have any dogs around? would you dare to let him lick your sweet pussy? :D',
            'that would be kinky as fuck!',
            'i dare you to get him in your room!!! i actually double dare you!!!',
            'c\'mon, is all about fun after all! and both of you will enjoy this! promise!',
            'well, is it a he or a she? :D',
            'is this the first time when you play with your dog like this? :D',
            'aren\'t you curious to see if he\'s gonna hump you if you\'d get in doggy position? :)',
            'no way, what about a hairbrush? an electric toothbrush? an ironcurl? some veggies :)',
            'well, you\'ll need a toy/object for the next stage :)',
          ],
        },
        { // Toy stage
          name: 'Toy',
          title: 'Toy stage',
          background: '/images/backgrounds/14.png',
          dares: [
            {'dare': 'Suck & lick toy / object'                        , points: 1000},
            {'dare': 'Deepthroat it as deep as you can a few times'    , points: 1000},
            {'dare': 'BONUS: Drool all over while you deepthroating it', points: 2000},
            {'dare': 'Pussy fuck with toy / object'                    , points: 1000},
          ],
          messages: [
            'you\'ll def need something longer and thicker :)',
            'oh my, you really know how to do this!',
            'show me how deep can you suck it baby!, i wanna see your gag reflex :)',
            'now don\'t be afraid to get some messy!!!',
            'just show me how dirty you like to be!',
            'let\'s get it nice and wet for your horny pussy',
            'first push it all way in slooowwwlllyyyy',
            'now start to pound it slowly first and increase the pace over time',
            'increase the pace a bit now',
          ],
        },
        { // Playoffs 1
          name: 'PO 1',
          title: 'Playoffs 1',
          background: '/images/backgrounds/15.png',
          dares: [
            {'dare': 'Slap clit hard a few times'             , points: 1000},
            {'dare': 'Spread pussy wide using your both hands', points: 1000},
            {'dare': 'Get an orgasm & moan'                   , points: 4000},
            {'dare': ''},
            {'dare': 'Are you into anal things?'              , points: 1000},
          ],
          messages: [
            'naaaaah, slap it harder baby!!',
            'harder!!!!',
            'HARDER!',
            'damn, i bet that that clit is sooo fucking sensitive rn...',
            'do you have any belt around? :D',
            'baby, relax on your back and get the middle and ring fingers slowly all way in',
            'then start to draw some circles with them in there',
            'make sure to give your gspot a bit of love',
            'now do it faster',
            'don\'t ignore your clit, rub it with the free hand',
            'now i want you show me how flexible you are. Get on your back, knees on your chest, arms between legs, elbows under knees',
            'let\'s pound that horny pussy baby, just take it!',
            'is today a good day to play with your ass?',
          ],
        },
        { // Playoffs 2
          name: 'PO 2',
          title: 'Playoffs 2',
          background: '/images/backgrounds/16.png',
          dares: [
            {'dare': 'Grab and put another toy in your ass and keep it in there for the rest of the game', points: 2000},
            {'dare': 'Stretch pussy open wide'                                                           , points: 2000},
            {'dare': 'Fuck pussy again with the old toy (Double Penetration)'                            , points: 2000},
          ],
          messages: [
            'why don\'t you just drool on it? that would help a lot',
            'show me how deep can it go in there',
          ],
        },
        { // Playoffs 3
          name: 'PO 3',
          title: 'Playoffs 3',
          background: '/images/backgrounds/02.png',
          dares: [
            {'dare': 'Put three fingers in pussy'  , points: 2000},
            {'dare': 'Suck & lick those fingers'   , points: 2000},
            {'dare': 'Continue the DP & cum again!', points: 5000},
            {'dare': 'Write \'SYNCRO\' on ur body' , points: 3000},
            {'dare': ''},
            {'dare': 'Your custom dare (your kink)', points: 5000},
          ],
          messages: [
            'mmmmm, those fingers really strectch your pussy so good...',
            'your custom dare can be anything unusual you\'d like to try',
            'preferably, it should still be something new for you too',
            'well, may i suggest a few things? just don\'t be scared',
            'do you have a dog? would you dare to let him to lick your sweet pussy? that would be kinky as fuck :)',
            'what is the strangest and weirdest object you can find and fuck it right now?',
            'or maybe grab a belt, tighten it around your neck, and start fucking yourself using your fingers?',
          ],
        },
        { // Fetishes stage
          name: 'Fetish',
          title: 'Fetishes stage',
          background: '/images/backgrounds/03.png',
          dares: [
            {'dare': 'Melt an ice cube in your pussy' , points: 5000},
            {'dare': 'Fuck and choke yourself'        , points: 5000},
            {'dare': 'Let your pet to lick your kitty', points: 5000},
            {'dare': '4 fingers or fist your pussy'   , points: 5000},
            {'dare': 'Fuck a really weird object'     , points: 5000},
            {'dare': 'Squirt / pee / hot wax'         , points: 5000},
          ],
          messages: [],
        },
      ],
    },

    /**
     * Double girl game
     */
    {
      name: 'Double girl',
      topScore: 'Brit & Morgan 100k',
      stages: [
        { // Points game
          name: 'Logo',
          title: 'Girls & couples game',
          background: '/images/backgrounds/01.png',
          dares: [
            {'dare': ''},
            {'dare': 'Are you looking for some real fun?'},
            {'dare': 'Do you feel naughty and horny?'},
            {'dare': ''},
            {'dare': 'Give me a heart or type START'},
          ],
          messages: [
            'hey',
            'hey there!',
            'are you even real?',
            'how are you?',
            'kind a bored :)',
            'are you looking for some real fun?',
            'would you prefer to play the game or just take orders? :D',
            'huh, it says you may be a simulated cam',
          ],
        },
        { // Hello stage
          name: 'Hello',
          title: 'Hello stage',
          background: '/images/backgrounds/02.png',
          dares: [
            {'dare': 'Type your age (both)'  , points: 10},
            {'dare': 'Wave your hand (both)' , points: 20},
            {'dare': 'Type your name (both)' , points: 30},
            {'dare': 'Give me a smile (both)', points: 40},
            {'dare': 'Bite your lips (both)' , points: 50},
          ],
          messages: [
            'hey',
            'hey there!',
            'hey sexy!',
            'wanna skip the face ones?',
            'fair enough :)',
            'if you want you can hide your face but please don\'t cover the cam anymore, ok?',
            'damn, cute smiles! <3',
            'actually killing smiles :)',
          ],
        },
        { // Level 1
          name: 'L1',
          title: 'Level 1',
          background: '/images/backgrounds/03.png',
          dares: [
            {'dare': 'Suck each other\'s finger '    , points: 50},
            {'dare': 'Suck each other two fingers'   , points: 60},
            {'dare': 'Kiss each other with tongue in', points: 70},
            {'dare': 'Show boobs cleavage (both)'    , points: 80},
            {'dare': 'Play with each other boobs'    , points: 90},
          ],
          messages: [
            'mmmmmmmmm, lovely sensual lips...',
            'you really love to suck on them, don\'t you? :D',
            'wow!!! unreal hot boobs!',
            'such a sexy view',
          ],
        },
        { // Level 2
          name: 'L2',
          title: 'Level 2',
          background: '/images/backgrounds/04.png',
          dares: [
            {'dare': 'Lift up each other\'s top and show bra or boobs'       , points: 250},
            {'dare': ''},
            {'dare': 'Pull off each other\'s pants and show panties or kitty', points: 250},
          ],
          messages: [
            'omg, those boobs must be out of this world!',
          ],
        },
        { // Flash stage
          name: 'Flash',
          title: 'Flash stage',
          background: '/images/backgrounds/05.png',
          dares: [
            {'dare': 'Take off each other\'s top'  , points: 250},
            {'dare': 'Flash your boobs'            , points: 250},
            {'dare': ''},
            {'dare': 'Take off each other\'s pants', points: 250},
            {'dare': 'Flash your pussy'            , points: 250},
          ],
          messages: [
            'well, is your door locked? :D',
            'you\'ll have to take them off and keep them off tho :)',
            'wow!!! unreal hot boobs!',
            'omg, those boobs must be out of this world!',
            'mmmmmmmmm, there is a matching outfit! sexy as fuck!',
          ],
        },
        { // Level 3
          name: 'L3',
          title: 'Level 3',
          background: '/images/backgrounds/06.png',
          dares: [
            {'dare': 'Take off each other\'s bra'     , points: 1000},
            {'dare': ''},
            {'dare': 'Bounce boobs (both)'            , points:  500},
            {'dare': 'Play with each other\'s boobs'  , points:  500},
            {'dare': 'Play with each other\'s nipples', points:  500},
            {'dare': 'Jiggle each other\'s boobs'     , points:  500},
          ],
          messages: [
            'do you have any extra lights in there?',
            'wow, just look at those gorgeous perky boobs!',
            'just to bounce them from side to side :)',
            'and those cute puffy nips are sooo fucking suckable baby!!!',
          ],
        },
        { // Doggy stage 1st girl
          name: 'Doggy1',
          title: 'Doggy stage 1st girl',
          background: '/images/backgrounds/07.png',
          dares: [
            {'dare': 'Doggy style - ass toward cam'                               , points: 1000},
            {'dare': 'Help ur friend by pulling her panties tight against her ass', points: 1000},
            {'dare': 'Slowly lower her panties'                                   , points: 1000},
            {'dare': 'Take off her panties'                                       , points: 1000},
            {'dare': 'BONUS: Play with her pussy'                                 , points: 1000},
          ],
          messages: [
            'what a fucking beautiful sexy ass!',
            'damn, probably you have the best ass around EVER!',
            'that\'s not enough :) slap them harder!',
            'baby, grab those sexy cheeks and pull them aside',
          ],
        },
        { // Ass stage 1st girl
          name: 'Ass1',
          title: 'Ass stage 1st girl',
          background: '/images/backgrounds/08.png',
          dares: [
            {'dare': 'Slap her ass hard a few times'                                                                                , points:  500},
            {'dare': 'Help your friend by spreading her ass cheeks and pussy with your both hands while she\'s face down and ass up', points: 1000},
            {'dare': 'BONUS: Finger your friend\'s pussy for one minute'                                                            , points: 2500},
          ],
          messages: [
            'you may skip the last dare if you want to. I respect your privacy!',
            'I just love your gorgeous sexy ass baby',
            'def the best ass ever!',
            'grab those sexy cheeks and spread that hot ass wider',
          ],
        },
        { // Doggy stage 2nd girl
          name: 'Doggy2',
          title: 'Doggy stage 2nd girl',
          background: '/images/backgrounds/09.png',
          dares: [
            {'dare': 'Switch places between you'                                  , points: 1000},
            {'dare': 'Help ur friend by pulling her panties tight against her ass', points: 1000},
            {'dare': 'Slowly lower her panties'                                   , points: 1000},
            {'dare': 'Take off her panties'                                       , points: 1000},
            {'dare': 'BONUS: Play with her pussy'                                 , points: 1000},
          ],
          messages: [
            'what a fucking beautiful sexy ass!',
            'damn, probably you have the best ass around EVER!',
            'that\'s not enough :) slap them harder!',
            'baby, grab those sexy cheeks and pull them aside',
          ],
        },
        { // Ass stage 2nd girl
          name: 'Ass2',
          title: 'Ass stage 2nd girl',
          background: '/images/backgrounds/10.png',
          dares: [
            {'dare': 'Slap her ass hard a few times'                                                                                , points:  500},
            {'dare': 'Help your friend by spreading her ass cheeks and pussy with your both hands while she\'s face down and ass up', points: 1000},
            {'dare': 'BONUS: Finger your friend\'s pussy for one minute'                                                            , points: 2500},
          ],
          messages: [
            'you may skip the last dare if you want to. I respect your privacy!',
            'I just love your gorgeous sexy ass baby',
            'def the best ass ever!',
            'grab those sexy cheeks and spread that hot ass wider',
          ],
        },
        { // Level 4
          name: 'L4',
          title: 'Level 4',
          background: '/images/backgrounds/11.png',
          dares: [
            {'dare': 'Clit close-up (both)'                              , points:  500},
            {'dare': 'Spread pussy wide (both)'                          , points:  500},
            {'dare': 'Play with each other\'s pussy'                     , points:  500},
            {'dare': 'Are you still a virgin?'                           , points:  500},
            {'dare': 'Get & remain naked for the rest of the game (both)', points: 1000},
          ],
          messages: [
            'the clit is your pussy\s "nose" :)',
            'omfg, you have such a fucking perfect hot body baby!!!',
            'you\'re such a fucking sex goddess <3',
            'oh my, just open that clit\'s hood!',
            'baby, wouldn\'t you feel more comfortable if you\'d move in bed for the rest of the game?',
            'baby, wouldn\'t you feel more comfortable if you\'d move on the floor for the rest of the game?',
          ],
        },
        { // Boobs stage
          name: 'Boobs',
          title: 'Boobs stage',
          background: '/images/backgrounds/12.png',
          dares: [
            {'dare': 'Spit & lick each other\'s nips'                                        , points: 1000},
            {'dare': 'Play with each other\'s boobs'                                         , points: 1000},
            {'dare': 'Pinch each other\'s nipples'                                           , points: 1000},
            {'dare': 'BONUS: Jiggle each other\'s boobs while pinching & pulling her nipples', points: 2000},
          ],
          messages: [
            'holly molly, those sexy boobs are killing me!',
            'pinch them even harder!'
          ],
        },
        { // Pussy stage 1st girl
          name: 'Pussy1',
          title: 'Pussy stage 1st girl',
          background: '/images/backgrounds/13.png',
          dares: [
            {'dare': '1st girl: Lay on your back'                                 , points: 1000},
            {'dare': 'Slowly put one finger all way in in your friend\'s pussy'   , points: 1000},
            {'dare': 'Slowly slip 2nd finger in her pussy and fuck it for a while', points: 1000},
            {'dare': 'BONUS: Keep playing with her pussy and clit while she moans', points: 2000},
          ],
          messages: [
            'get that sweet pussy closer please',
            'mmmmmmmmmmm, that sweet pussy looks sooo fucking wet and horny!',
            'just love how wet sounds your pussy!!!',
            'how many fingers can you take in there? :D',
            'mmmmmmm, that must feel soooo fucking good rn!',
            'that\'s a really happy and lucky pussy baby! <3',
          ],
        },
        { // Bonus stage 1st girl
          name: 'Bonus1',
          title: 'Bonus stage 1st girl',
          background: '/images/backgrounds/14.png',
          dares: [
            {'dare': 'Put one finger from each hand in your girlfriend\'s pussy and use them like some hooks to spread it as wide as you can!', points: 2000},
            {'dare': ''},
            {'dare': 'BONUS: Drool on ur friend\'s pussy and eat her out'                                                                     , points: 3000},
          ],
          messages: [
            'get that sweet pussy closer',
            'fuck baby, you have such a beautiful rose down there!',
            'do it again please, even deeper and wider this time please!',
            'baby, wanna do it again using two fingers from each hand this time?',
          ],
        },
        { // Pussy stage 2nd girl
          name: 'Pussy2',
          title: 'Pussy stage 2nd girl',
          background: '/images/backgrounds/15.png',
          dares: [
            {'dare': 'Switch places between you'                                  , points: 1000},
            {'dare': 'Slowly put one finger all way in in your friend\'s pussy'   , points: 1000},
            {'dare': 'Slowly slip 2nd finger in her pussy and fuck it for a while', points: 1000},
            {'dare': 'BONUS: Keep playing with her pussy and clit while she moans', points: 2000},
          ],
          messages: [
            'get that sweet pussy closer please',
            'mmmmmmmmmmm, that sweet pussy looks sooo fucking wet and horny!',
            'just love how wet sounds your pussy!!!',
            'how many fingers can you take in there? :D',
            'mmmmmmm, that must feel soooo fucking good rn!',
            'that\'s a really happy and lucky pussy baby! <3',
          ],
        },
        { // Bonus stage 2nd girl
          name: 'Bonus2',
          title: 'Bonus stage 2nd girl',
          background: '/images/backgrounds/16.png',
          dares: [
            {'dare': 'Switch places and put one finger from each hand in your girlfriend\'s pussy and use them like some hooks to spread it as wide as you can!', points: 2000},
            // {'dare': ''},
            {'dare': 'BONUS: Drool on ur friend\'s pussy and eat her out'                                                                                       , points: 3000},
          ],
          messages: [
            'get that sweet pussy closer',
            'fuck baby, you have such a beautiful rose down there!',
            'do it again please, even deeper and wider this time please!',
            'baby, wanna do it again using two fingers from each hand this time?',
          ],
        },
        { // Kinks stage
          name: 'Kinks',
          title: 'Kinks stage',
          background: '/images/backgrounds/02.png',
          dares: [
            {'dare': 'Are you into kinky dirty filthy things?'                  , points: 1000},
            {'dare': 'What was the kinkiest thing you ever did with your pussy?', points: 1000},
            {'dare': 'Type your real ages'                                      , points: 2000},
            {'dare': 'Grab some toys or objects'                                , points: 1000},
          ],
          messages: [
            'hehe, that\'s hot as fuck but not kinky at all :))',
            'what about to dare you something really kinky? i bet that you\'ll love it!',
            'just don\'t get too scared please :)',
            'do you have any dogs around? would you dare to let him lick your sweet pussy? :D',
            'that would be kinky as fuck!',
            'i dare you to get him in your room!!! i actually double dare you!!!',
            // 'c\'mon, is all about fun after all! and both of you will enjoy this! promise!',
            // 'well, is it a he or a she? :D',
            // 'aren\'t you curious to see if he\'s gonna hump you if you\'d get in doggy position? :)',
          ],
        },
        { // 69 stage
          name: '69',
          title: '69 stage',
          background: '/images/backgrounds/03.png',
          dares: [
            {'dare': 'One of you get on top of the other in 69 position', points: 1000},
            {'dare': 'Lick on each other\'s pussy'                      , points: 1000},
            {'dare': 'Finger each other\'s pussy'                       , points: 1000},
            {'dare': 'Use some toys to fuck each other\'s pussy'        , points: 2000},
          ],
          messages: [
            'oh my, you really know how to do this!',
            'now don\'t be afraid to get some messy!!!',
            'first push it all way in slooowwwlllyyyy',
            'now start to pound it slowly first and increase the pace over time',
            'increase the pace a bit now',
          ],
        },
        { // Toy stage
          name: 'Toy',
          title: 'Toy stage',
          background: '/images/backgrounds/04.png',
          dares: [
            {'dare': 'Suck & lick your own toy'                              , points: 1000},
            {'dare': 'Deepthroat your own toy as deep as you can a few times', points: 1000},
            {'dare': 'BONUS: Drool all over while you deepthroating it'      , points: 1000},
            {'dare': 'Fuck your own pussy with toy'                          , points: 2000},
          ],
          messages: [
            'you\'ll def need something longer and thicker :)',
            'show me how deep can you suck it baby, i wanna see your gag reflex :)',
            'don\'t be afraid to get some messy there',
            'first push it all way in slooowwwlllyyyy',
            'now start to pound it slowly first and increase the pace over time',
            'increase the pace a bit now',
          ],
        },
        { // Anal question
          name: 'Anal?',
          title: 'Anal question',
          background: '/images/backgrounds/05.png',
          dares: [
            {'dare': '',},
            {'dare': 'Are you into anal things?'},
            {'dare': '',},
            {'dare': 'YES'                      , points: 1000},
            {'dare': 'NO'                       , points: 0},
          ],
          messages: [
            'is today a good day to play with your ass?',
          ],
        },
        { // Anal stage 1st girl
          name: 'Anal1',
          title: 'Anal stage 1st girl',
          background: '/images/backgrounds/06.png',
          dares: [
            {'dare': '1st girl: Get in doggy again'                        , points: 1000},
            {'dare': 'Finger tease your friend\'s ass'                     , points: 1000},
            {'dare': 'Slip it in as deep as she like'                      , points: 1000},
            {'dare': 'Grab a toy and fuck her ass'                         , points: 1000},
            {'dare': 'Grab a 2nd toy and fuck her both holes until she cum', points: 2000},
          ],
          messages: [
            'baby, get the middle and ring fingers slowly all way in',
            'now do it faster',
            'don\'t ignore her clit, rub it with the free hand',
            'let\'s pound that horny pussy baby, just take it!',
            'make sure to give your gspot a bit of love',
          ],
        },
        { // Anal stage 2nd girl
          name: 'Anal2',
          title: 'Anal stage 2nd girl',
          background: '/images/backgrounds/07.png',
          dares: [
            {'dare': '2nd girl: Get in doggy again'                        , points: 1000},
            {'dare': 'Finger tease your friend\'s ass'                     , points: 1000},
            {'dare': 'Slip it in as deep as she like'                      , points: 1000},
            {'dare': 'Grab a toy and fuck her ass'                         , points: 1000},
            {'dare': 'Grab a 2nd toy and fuck her both holes until she cum', points: 2000},
          ],
          messages: [
            'baby, get the middle and ring fingers slowly all way in',
            'now do it faster',
            'don\'t ignore her clit, rub it with the free hand',
            'let\'s pound that horny pussy baby, just take it!',
            'make sure to give your gspot a bit of love',
          ],
        },
        { // Playoffs 1
          name: 'PO 1',
          title: 'Playoffs 1',
          background: '/images/backgrounds/08.png',
          dares: [
            {'dare': 'Play with your friend\'s pussy using your mouth & tongue', points: 1000},
            {'dare': 'Finger your friend\'s pussy'                             , points: 1000},
            {'dare': 'Switch places and repeat'                                , points: 2000},
            {'dare': ''},
            {'dare': 'Scissors fuck each other'                                , points: 2000},
          ],
          messages: [],
        },
        { // Playoffs 2
          name: 'PO 2',
          title: 'Playoffs 2',
          background: '/images/backgrounds/09.png',
          dares: [
            {'dare': 'Freestyle fuck and play with each other\'s body and pussy using your fingers and toys until both of you cum again', points: 4000},
            {'dare': ''},
            {'dare': 'Kiss on each other again'                                                                                         , points: 1000},
          ],
          messages: [],
        },
        { // Playoffs 3
          name: 'PO 3',
          title: 'Playoffs 3 (both players)',
          background: '/images/backgrounds/10.png',
          dares: [
            {'dare': 'Put three fingers in pussy'          , points: 2000},
            {'dare': 'Suck & lick those fingers'           , points: 1000},
            {'dare': 'Write \'SYNCRO\' on ur body'         , points: 2000},
            {'dare': ''},
            {'dare': 'Your custom dare (your kink)'        , points: 5000},
          ],
          messages: [],
        },
        { // Fetishes stage
          name: 'Fetish',
          title: 'Fetishes stage',
          background: '/images/backgrounds/11.png',
          dares: [
            {'dare': 'Melt an ice cube in your pussy' , points: 5000},
            {'dare': 'Fuck and choke yourself'        , points: 5000},
            {'dare': 'Let your pet to lick your kitty', points: 5000},
            {'dare': '4 fingers or fist your pussy'   , points: 5000},
            {'dare': 'Fuck a really weird object'     , points: 5000},
            {'dare': 'Squirt / pee / hot wax'         , points: 5000},
          ],
          messages: [],
        },
      ],
    },

    /**
     * Couple game
     */
    {
      name: 'Couple',
      topScore: 'Kevin & Vicky 100k',
      stages: [
        { // Points game
          name: 'Logo',
          title: 'Girls & couples game',
          background: '/images/backgrounds/01.png',
          dares: [
            {'dare': ''},
            {'dare': 'Are you looking for some real fun?'},
            {'dare': 'Do you feel naughty and horny?'},
            {'dare': ''},
            {'dare': 'Give me a heart or type START'},
          ],
          messages: [
            'hey',
            'hey there!',
            'are you even real?',
            'how are you?',
            'kind a bored :)',
            'are you looking for some real fun?',
            'would you prefer to play the game or just take orders? :D',
            'huh, it says you may be a simulated cam',
          ],
        },
        { // Hello stage
          name: 'Hello',
          title: 'Hello stage',
          background: '/images/backgrounds/02.png',
          dares: [
            {'dare': 'Type your age (both)'  , points: 10},
            {'dare': 'Wave your hand (both)' , points: 20},
            {'dare': 'Type your name (both)' , points: 30},
            {'dare': 'Give me a smile (both)', points: 40},
            {'dare': 'Bite your lips (both)' , points: 50},
          ],
          messages: [
            'hey',
            'hey there!',
            'hey sexy!',
            'wanna skip the face ones?',
            'fair enough :)',
            'if you want you can hide your face but please don\'t cover the cam anymore, ok?',
            'damn, cute smile! <3',
            'actually a killing smile :)',
          ],
        },
        { // Level 1
          name: 'L1',
          title: 'Level 1',
          background: '/images/backgrounds/03.png',
          dares: [
            {'dare': 'Suck each other\'s finger '         , points: 50},
            {'dare': 'Suck each other two fingers'        , points: 60},
            {'dare': 'Kiss each other with tongue in'     , points: 70},
            {'dare': 'Help her to show her boobs cleavage', points: 80},
            {'dare': 'Tease your girlfriend boobs'        , points: 90},
          ],
          messages: [
            'mmmmmmmmm, lovely sensual lips...',
            'you really love to suck on them, don\'t you? :D',
            'wow!!! unreal hot boobs baby!',
            'such a sexy view baby',
          ],
        },
        { // Level 2
          name: 'L2',
          title: 'Level 2',
          background: '/images/backgrounds/04.png',
          dares: [
            {'dare': 'Lift her top and show bra or boobs'      , points: 250},
            {'dare': ''},
            {'dare': ''},
            {'dare': 'Pull her pants and show panties or kitty', points: 250},
          ],
          messages: [
            'omg, those boobs must be out of this world!',
          ],
        },
        { // Flash stage
          name: 'Flash',
          title: 'Flash stage',
          background: '/images/backgrounds/05.png',
          dares: [
            {'dare': 'Take off each other\'s top'  , points: 250},
            {'dare': 'Flash your boobs/abdomen'    , points: 250},
            {'dare': ''},
            {'dare': 'Take off each other\'s pants', points: 250},
            {'dare': 'Flash your pussy/cock'       , points: 250},
          ],
          messages: [
            'well, is your door locked? :D',
            'you\'ll have to take them off and keep them off tho :)',
            'wow!!! unreal hot boobs baby!',
            'omg, those boobs must be out of this world!',
            'mmmmmmmmm, matching outfit! sexy as fuck!',
          ],
        },
        { // Level 3
          name: 'L3',
          title: 'Level 3',
          background: '/images/backgrounds/06.png',
          dares: [
            {'dare': 'Take off her bra'        , points: 1000},
            {'dare': ''},
            {'dare': 'Bounce boobs'            , points:  500},
            {'dare': 'Play with her boobs'     , points:  500},
            {'dare': 'Play with her nipples'   , points:  500},
            {'dare': 'Help her to jiggle boobs', points:  500},
          ],
          messages: [
            'please baby, do you have any extra lights in there?',
            'wow, just look at those gorgeous perky boobs!',
            'just to bounce them from side to side :)',
            'and those cute puffy nips are sooo fucking suckable baby!!!',
          ],
        },
        { // Doggy stage
          name: 'Doggy',
          title: 'Doggy stage (girl only)',
          background: '/images/backgrounds/08.png',
          dares: [
            {'dare': 'Girl: Doggy style - ass toward cam'       , points: 1000},
            {'dare': 'Pull panties tight against her ass'       , points: 1000},
            {'dare': 'Slowly lower her panties'                 , points: 1000},
            {'dare': 'Slap her ass hard a few times'            , points: 1000},
            {'dare': 'BONUS: Play with her pussy while in doggy', points: 2000},
          ],
          messages: [
            'what a fucking beautiful sexy ass!',
            'damn, probably you have the best ass around EVER!',
            'that\'s not enough :) slap them harder!',
            'baby, grab those sexy cheeks and pull them aside',
          ],
        },
        { // Level 4
          name: 'L4',
          title: 'Level 4',
          background: '/images/backgrounds/07.png',
          dares: [
            {'dare': 'Take off each other\'s panties'                  , points: 1000},
            {'dare': 'Show her clit close-up'                          , points:  500},
            {'dare': 'Spread her pussy wide'                           , points:  500},
            {'dare': 'Have you had sex together so far?'               , points:  500},
            {'dare': 'Both get & remain naked for the rest of the game', points:  500},
          ],
          messages: [
            'the clit is your pussy\s "nose" :)',
            'why don\t you try to get that phone against something so that it will stay alone? you\'ll get an extra free hand :)',
            'omfg, you have such a fucking perfect hot body baby!!!',
            'you\'re such a fucking sex goddess <3',
            'oh my, just open that clit\'s hood!',
            'baby, wouldn\'t you feel more comfortable if you\'d move in bed for the rest of the game?',
            'baby, wouldn\'t you feel more comfortable if you\'d move on the floor for the rest of the game?',
          ],
        },
        { // Ass stage
          name: 'Ass',
          title: 'Ass stage',
          background: '/images/backgrounds/08.png',
          dares: [
            {'dare': 'Let her get in doggy position'                                                    , points:  500},
            {'dare': 'Spread her ass cheeks and pussy with both hands while she\'s face down and ass up', points: 1500},
            {'dare': 'Play with her pussy while she\'s spreading wide her ass cheeks'                   , points: 2000},
          ],
          messages: [
            'you may skip the last dare if you want to. I respect your privacy!',
            'I just love your gorgeous sexy ass baby',
            'def the best ass ever!',
            'grab those sexy cheeks and spread that hot ass wider',
          ],
        },
        { // Boobs stage
          name: 'Boobs',
          title: 'Boobs stage',
          background: '/images/backgrounds/09.png',
          dares: [
            {'dare': 'Show boobs (close)'                                          , points: 500 },
            {'dare': 'Squeeze her boobs together'                                  , points: 500},
            {'dare': 'Spit & lick her nipples'                                     , points: 1000},
            {'dare': 'Pinch her nipples hard'                                      , points: 1000},
            {'dare': 'BONUS: Jiggle her boobs while pinching & pulling her nipples', points: 2000},
          ],
          messages: [
            'holly molly, those sexy boobs are killing me!',
            'pinch them even harder!'
          ],
        },
        { // Pussy stage
          name: 'Pussy',
          title: 'Pussy stage',
          background: '/images/backgrounds/10.png',
          dares: [
            {'dare': 'Lay on your back and show pussy close-up', points: 1000},
            {'dare': 'One finger in her pussy'                 , points: 1000},
            {'dare': 'Two fingers in her pussy'                , points: 1000},
            {'dare': 'Spread her pussy lips'                   , points: 1000},
            {'dare': 'Play with her pussy while she moans'     , points: 1000},
          ],
          messages: [
            'get that sweet pussy closer please',
            'mmmmmmmmmmm, that sweet pussy looks sooo fucking wet and horny!',
            'just love how wet sounds your pussy!!!',
            'how many fingers can you take in there? :D',
            'no fucking way!!! are you kidding me? :O',
            'mmmmmmm, that must feel soooo fucking good rn!',
            'that\'s a really happy and lucky pussy baby! <3',
            'hold your leg back and rub that sweet clit',
          ],
        },
        { // Bonus stage
          name: 'Bonus',
          title: 'Bonus stage',
          background: '/images/backgrounds/11.png',
          dares: [
            {'dare': 'Put one finger from each hand in your girlfriend\'s pussy and use them like some hooks to spread it as wide as you can, while she\'s leaning on her back!', points: 2000},
            {'dare': ''},
            {'dare': 'BONUS: Type your both real age'                                                                                                      , points: 2000},
          ],
          messages: [
            'get that sweet pussy closer',
            'fuck baby, you have such a beautiful rose down there!',
            'do it again please, even deeper and wider this time please!',
            'baby, wanna do it again using two fingers from each hand this time?',
          ],
        },
        { // Sex stage
          name: 'Sex',
          title: 'Sex stage',
          background: '/images/backgrounds/13.png',
          dares: [
            {'dare': 'Get him hard with a handjob'                  , points: 1000},
            {'dare': 'Lick & suck his hard cock'                    , points: 1000},
            {'dare': 'Deepthroat his cock as deep as you can'       , points: 1000},
            {'dare': 'BONUS: Drool all over while deepthroating him', points: 2000},
            {'dare': 'Fuck her pussy'                               , points: 1000},
          ],
          messages: [
            'no way... what about a hairbrush? an electric toothbrush? or an ironcurl?',
            'you\'ll def need something longer and thicker :)',
            'oh my, you really know how to do this!',
            'show me how deep can you suck it baby!',
            'i wanna see your gag reflex :)',
            'now don\'t be afraid to get some messy!!!',
            'first push it all way in slooowwwlllyyyy',
            'now start to pound it slowly first and increase the pace over time',
            'increase the pace a bit now',
          ],
        },
        { // Playoffs 1
          name: 'PO 1',
          title: 'Playoffs 1',
          background: '/images/backgrounds/14.png',
          dares: [
            {'dare': 'Are you into anal things?'                          , points: 1000},
            {'dare': ''},
            {'dare': 'Get her in doggy and tease her ass with your finger', points: 2000},
            {'dare': 'Spread her ass and fuck it'                         , points: 2000},
            {'dare': 'Pussy fuck until you both cum'                      , points: 2000},
          ],
          messages: [
            'is today a good day to play with your ass?',
            'naaaaah, slap it harder baby!!',
            'harder!!!!',
            'HARDER!',
            'damn, i bet that that clit is sooo fucking sensitive rn...',
            'do you have any belt around? :D',
            'baby, get the middle and ring fingers slowly all way in',
            'then start to draw some circles with them in there',
            'now do it faster',
            'don\'t ignore your clit, rub it with the free hand',
            'let\'s pound that horny pussy baby, just take it!',
            'make sure to give your gspot a bit of love',
          ],
        },
        { // Playoffs 2
          name: 'PO 2',
          title: 'Playoffs 2',
          background: '/images/backgrounds/15.png',
          dares: [
            {'dare': 'Play with your friend\'s pussy using your mouth & tongue'                 , points: 1500},
            {'dare': 'Finger your friend\'s pussy'                                              , points: 1500},
            {'dare': ''},
            {'dare': 'Fuck her pussy hard in doggy while she\'s rubbing her pussy and cum again', points: 2000},
          ],
          messages: [],
        },
        { // Playoffs 3
          name: 'PO 3',
          title: 'Playoffs 3',
          background: '/images/backgrounds/16.png',
          dares: [
            {'dare': 'Put three fingers in her pussy'                                  , points: 1000},
            {'dare': 'Suck & lick those fingers'                                       , points: 1000},
            {'dare': 'Grab a toy and fuck her mouth again while she\'s fucking herself', points: 3000},
            {'dare': ''},
            {'dare': 'Your custom dare (your kink)'                                    , points: 5000},
          ],
          messages: [],
        },
        { // Fetishes stage
          name: 'Fetish',
          title: 'Fetishes stage',
          background: '/images/backgrounds/01.png',
          dares: [
            {'dare': 'Melt an ice cube in your pussy' , points: 5000},
            {'dare': 'Fuck and choke yourself'        , points: 5000},
            {'dare': 'Let your pet to lick your kitty', points: 5000},
            {'dare': '4 fingers or fist your pussy'   , points: 5000},
            {'dare': 'Fuck a really weird object'     , points: 5000},
            {'dare': 'Squirt / pee / hot wax'         , points: 5000},
          ],
          messages: [],
        },
      ],
    },

    /**
     * Guy game
     */
    {
      name: 'Guy',
      stages: [
        { // Points game
          name: 'Logo',
          title: 'Girls & couples game',
          background: '/images/backgrounds/01.png',
          dares: [
            {'dare': ''},
            {'dare': 'Are you looking for some real fun?'},
            {'dare': 'Do you feel naughty and horny?'},
            {'dare': ''},
            {'dare': 'Give me a heart or type START'},
          ],
          messages: [
            'hey',
            'hey there!',
            'are you even real?',
            'how are you?',
            'kind a bored :)',
            'are you looking for some real fun?',
            'would you prefer to play the game or just take orders? :D',
            'huh, it says you may be a simulated cam',
          ],
        },
        { // Hello stage
          name: 'Hello',
          title: 'Hello stage',
          background: '/images/backgrounds/02.png',
          dares: [
            {'dare': 'Type your age'  , points: 10},
            {'dare': 'Wave your hand' , points: 20},
            {'dare': 'Type your name' , points: 30},
            {'dare': 'Give me a smile', points: 40},
            {'dare': 'Bite your lips' , points: 50},
          ],
          messages: [
            'hey',
            'hey there!',
            'wanna skip the face ones?',
            'fair enough :)',
            'if you want you can hide your face but please don\'t cover the cam anymore, ok?',
          ],
        },
        { // Level 1
          name: 'L1S',
          title: 'Level 1',
          background: '/images/backgrounds/03.png',
          dares: [
            {'dare': 'Suck one finger'   , points: 50},
            {'dare': 'Suck two fingers'  , points: 60},
            {'dare': 'Lick your lips'    , points: 70},
            {'dare': 'Pinch your nipples', points: 80},
            {'dare': 'Show abdomen'      , points: 90},
          ],
          messages: [],
        },
        { // Level 2
          name: 'L2',
          title: 'Level 2',
          background: '/images/backgrounds/04.png',
          dares: [
            {'dare': 'Lift up your top and show your boobs'        , points: 250},
            {'dare': ''},
            {'dare': ''},
            {'dare': 'Pull off your pants and show panties or cock', points: 250},
          ],
          messages: [],
        },
        { // Level 3
          name: 'L3',
          title: 'Level 3',
          background: '/images/backgrounds/05.png',
          dares: [
            {'dare': 'Take off your top'  , points: 250},
            {'dare': ''},
            {'dare': 'Take off your pants', points: 250},
            {'dare': ''},
            {'dare': 'Flash your cock'    , points: 500},
          ],
          messages: [
            'well, is your door locked? :D',
            'you\'ll have to take them off and keep them off tho :)',
            'wow!!! unreal hot boobs baby!',
            'omg, those boobs must be out of this world!',
            'mmmmmmmmm, matching outfit! sexy as fuck!',
          ],
        },
        { // Doggy stage
          name: 'Doggy',
          title: 'Doggy stage',
          gameTypes: ['Single girl'],
          background: '/images/backgrounds/08.png',
          dares: [
            {'dare': 'Doggy style - ass toward cam'                       , points: 1000},
            {'dare': 'Pull panties tight against ass'                     , points: 1000},
            {'dare': 'Slowly lower the panties'                           , points: 1000},
            {'dare': 'Shake / twerk / slap ass'                           , points: 1000},
            {'dare': 'BONUS: Play with your pussy and moan while in doggy', points: 2000},
          ],
          messages: [
            'what a fucking beautiful sexy ass!',
            'damn, probably you have the best ass EVER!',
            'that\'s not enough :) slap them harder!',
            'baby, grab those sexy cheeks and pull them aside',
          ],
        },
        { // Level 4
          name: 'L4',
          title: 'Level 4',
          gameTypes: ['Single girl'],
          background: '/images/backgrounds/07.png',
          dares: [
            {'dare': 'Take off your panties'                      , points: 1000},
            {'dare': 'Clit close-up'                              , points:  500},
            {'dare': 'Spread your pussy'                          , points:  500},
            {'dare': 'Are you still a virgin?'                    , points:  500},
            {'dare': 'Get & remain naked for the rest of the game', points:  500},
          ],
          messages: [
            'the clit is your pussy\s "nose" :)',
            'why don\t you try to get that phone against something so that it will stay alone? you\'ll get an extra free hand :)',
            'omfg, you have such a fucking perfect hot body baby!!!',
            'you\'re such a fucking sex goddess <3',
            'oh my, just open that clit\'s hood!',
            'baby, wouldn\'t you feel more comfortable if you\'d move in bed for the rest of the game?',
            'baby, wouldn\'t you feel more comfortable if you\'d move on the floor for the rest of the game?',
          ],
        },
        { // Ass stage
          name: 'Ass',
          title: 'Ass stage',
          background: '/images/backgrounds/08.png',
          dares: [
            {'dare': 'Bend over / doggy position'                                           , points:  500},
            {'dare': 'Arch your ass up while in doggy and spread ass cheeks with both hands', points: 1500},
            {'dare': 'BONUS: Watch at cam while spreading wide ass cheeks'                  , points: 2000},
          ],
          messages: [],
        },
        { // Cock stage
          name: 'Cock',
          title: 'Cock stage',
          background: '/images/backgrounds/10.png',
          dares: [
            {'dare': 'Lay on your back and show cock close-up', points: 1000},
            {'dare': 'Play with your balls'                   , points: 1000},
            {'dare': 'Play with your cock'                    , points: 1000},
            {'dare': 'Get that cock hard'                     , points: 1000},
            {'dare': 'Masturbate & moan'                      , points: 1000},
          ],
          messages: [],
        },
        { // Bonus stage
          name: 'Bonus',
          title: 'Bonus stage',
          background: '/images/backgrounds/11.png',
          dares: [
            {'dare': 'Leaning on your back, keep jerking off and tease your ass with a finger!', points: 2000},
            {'dare': ''},
            {'dare': 'BONUS: Type your real age'                                               , points: 2000},
          ],
          messages: [],
        },
        { // Kinks stage
          name: 'Kinks',
          title: 'Kinks stage',
          background: '/images/backgrounds/12.png',
          dares: [
            {'dare': 'Are you into kinky dirty filthy things?'                 , points: 1000},
            {'dare': 'What was the kinkiest thing you ever did with your cock?', points: 1000},
            {'dare': 'Taste your own precum juice'                             , points: 1000},
            {'dare': 'Grab a toy or an object'                                 , points: 1000},
          ],
          messages: [
            'no way... what about a hairbrush? an electric toothbrush? or an ironcurl?',
            'hehe, that\'s hot as fuck but not kinky at all :))',
            'what about to dare you something really kinky? i bet that you\'ll love it!',
            'just don\'t get too scared please :)',
            'that would be kinky as fuck!',
            'do you have any dogs around? would you dare to let him lick your sweet pussy? :D',
            'i dare you to get him in your room!!! i actually double dare you!!!',
            'c\'mon, is all about fun after all! and both of you will enjoy this! promise!',
            'well, is it a he or a she? :D',
            'is this the first time when you play with your dog like this? :D',
            'aren\'t you curious to see if he\'s gonna hump you if you\'d get in doggy position? :)',
          ],
        },
        { // Toy stage
          name: 'Toy',
          title: 'Toy stage',
          background: '/images/backgrounds/13.png',
          dares: [
            {'dare': 'Suck & lick toy / object'                        , points: 1000},
            {'dare': 'Deepthroat it as deep as you can a few times'    , points: 1000},
            {'dare': 'BONUS: Drool all over while you deepthroating it', points: 2000},
            {'dare': 'Ass fuck with toy / object'                      , points: 1000},
          ],
          messages: [
            'you\'ll def need something longer and thicker :)',
            'oh my, you really know how to do this!',
            'show me how deep can you suck it!',
            'i wanna see your gag reflex :)',
            'now don\'t be afraid to get some messy!!!',
            'first push it all way in slooowwwlllyyyy',
            'now start to pound it slowly first and increase the pace over time',
            'increase the pace a bit now',
          ],
        },
        { // Playoffs 1
          name: 'PO 1',
          title: 'Playoffs 1',
          background: '/images/backgrounds/14.png',
          dares: [
            {'dare': 'Get an orgasm & moan', points: 5000},
          ],
          messages: [
            'harder!!!!',
            'HARDER!',
            'do you have any belt around? :D',
            'now do it faster',
            'let\'s pound that horny ass, just take it!',
          ],
        },
        { // Playoffs 2
          name: 'PO 2',
          title: 'Playoffs 2',
          background: '/images/backgrounds/16.png',
          dares: [
            {'dare': 'Put three fingers in ass'    , points: 2000},
            {'dare': 'Suck & lick those fingers'   , points: 2000},
            {'dare': 'Write \'SYNCRO\' on ur body' , points: 3000},
            {'dare': 'Cum again!'                  , points: 5000},
            {'dare': ''},
            {'dare': 'Your custom dare (your kink)', points: 5000},
          ],
          messages: [],
        },
        { // Fetishes stage
          name: 'Fetish',
          title: 'Fetishes stage',
          background: '/images/backgrounds/01.png',
          dares: [
            {'dare': 'Melt an ice cube in your ass'  , points: 5000},
            {'dare': 'Fuck and choke yourself'       , points: 5000},
            {'dare': 'Let your pet to lick your cock', points: 5000},
            {'dare': '4 fingers or fist your ass'    , points: 5000},
            {'dare': 'Fuck a really weird object'    , points: 5000},
            {'dare': 'Hot wax'                       , points: 5000},
          ],
          messages: [],
        },
      ],
    },

  ];

  const refNextBoard = createRef();

  const [game, setGame] = useState(null);
  const [level, setLevel] = useState(0);
  const [completed, setCompleted] = useState([]);
  const [extraPoints, setExtraPoints] = useState(0);
  var [unicode, setUnicode] = useState(false);
  var [wtl, setWtl] = useState(null);
  var [wtr, setWtr] = useState(null);
  var [wbl, setWbl] = useState(null);
  var [wbr, setWbr] = useState(null);

  useEffect(() => {
    setGame(games[0]);
  }, []);
  useEffect(() => {
    if (game !== null)
      refNextBoard.current.focus();
  }, [game, refNextBoard]);
  useEffect(() => {
    if (wtl === null && wtr === null && wbl === null && wbr === null) {
      setGame(games[0]);
      setLevel(0);
      setCompleted([]);
      setExtraPoints(0);
    }
  }, [wtl, wtr, wbl, wbr]);

  const getScore = () => {
    var score = 0;

    game.stages.forEach(stage => {
      stage.dares.forEach(dare => {
        if (completed.includes(`${stage.name} - ${dare.dare}`))
          score = score + dare.points;
      })
    });

    return score + extraPoints;
  };
  const getOpenedWindows = () => {
    let count = 0;

    if (Boolean(wtl)) count++;
    if (Boolean(wtr)) count++;
    if (Boolean(wbl)) count++;
    if (Boolean(wbr)) count++;

    return count;
  };

  const openWindow = position => () => {
    var yGap = 120;
    var width = (window.screen.availWidth - window.outerWidth) / 2 + 2;
    var height = (window.screen.availHeight - yGap) / 2 - 3;
    var left = window.screen.availLeft;
    var top = window.screen.availTop;
    // const site = 'https://www.omegle.com/';
    const site = 'https://chatrandom.com/';
    switch (position) {
      case "wtl":
        // setWtl(window.open('https://chatrandom.com/', "_blank", `width=${width}, height=${height}, top=${top}, left=${left}`));
        setWtl(window.open('https://bazoocam.org/', "_blank", `width=${width}, height=${height}, top=${top}, left=${left}`));
        break;
      case "wtr":
        setWtr(window.open('https://flingster.com/', "_blank", `width=${width}, height=${height}, top=${top}, left=${left + width + 1}`));
        // setWtr(window.open('https://bazoocam.org/', "_blank", `width=${width}, height=${height}, top=${top}, left=${left + width + 1}`));
        break;
      case "wbl":
        setWbl(window.open('https://beta.emeraldchat.com/app/meet/VideoChat', "_blank", `width=${width + 120}, height=${height}, top=${height + yGap + 3}, left=${left}`));
        // setWbl(window.open('https://bazoocam.org/', "_blank", `width=${width}, height=${height}, top=${height + yGap + 3}, left=${left}`));
        break;
      case "wbr":
        setWbr(window.open('https://beta.emeraldchat.com/app/meet/VideoChat', "_blank", `width=${width}, height=${height}, top=${height + yGap + 3}, left=${left + width + 1}`));
        break;
      default: break;
    }
  };
  const closeWindow = position => () => {
    switch (position) {
      case "wtl":
        wtl.close();
        setWtl(null);
        break;
      case "wtr":
        wtr.close();
        setWtr(null);
        break;
      case "wbl":
        wbl.close();
        setWbl(null);
        break;
      case "wbr":
        wbr.close();
        setWbr(null);
        break;
      default: break;
    }
  };
  const closeAllWindows = () => {
    wtl && wtl.close();
    wtr && wtr.close();
    wbl && wbl.close();
    wbr && wbr.close();
    setWtl(null);
    setWtr(null);
    setWbl(null);
    setWbr(null);
    window.location.href = 'https://www.google.com';
  };
  const skipStranger = position => () => {
    switch (position) {
      case "wtl":
        wtl.postMessage({"target": 'wtl', "command": "nextStranger"}, "*");
        break;
      case "wtr":
        wtr.postMessage({"target": 'wtr', "command": "nextStranger"}, "*");
        break;
      case "wbl":
        wbl.postMessage({"target": 'wbl', "command": "nextStranger"}, "*");
        break;
      case "wbr":
        wbr.postMessage({"target": 'wbr', "command": "nextStranger"}, "*");
        break;
      default: break;
    }
  }
  const handleKeyUp = () => event => {
    switch (event.keyCode) {
      case 27: // ESCAPE -> close all windows
        closeAllWindows();
        break;
      // case 34: // PageDown -> minimze all windows
      //   $(".lvl17").trigger("click");
      //   wtl && wtl.postMessage({"target": $(this).attr("data-target"), "command": "minimize"}, "*");
      //   wtr && wtr.postMessage({"target": $(this).attr("data-target"), "command": "minimize"}, "*");
      //   wbl && wbl.postMessage({"target": $(this).attr("data-target"), "command": "minimize"}, "*");
      //   wbr && wbr.postMessage({"target": $(this).attr("data-target"), "command": "minimize"}, "*");
      //   currentLevel = $(".active:not(.lvl17)").length === 0 ? currentLevel : $(".active").eq(0);
      //   break;
      // case 192: // ` -> restore all windows
      // case 33: // PageUp -> restore all windows
      //   wtl && wtl.postMessage({"target": $(this).attr("data-target"), "command": "maximize"}, "*");
      //   wtr && wtr.postMessage({"target": $(this).attr("data-target"), "command": "maximize"}, "*");
      //   wbl && wbl.postMessage({"target": $(this).attr("data-target"), "command": "maximize"}, "*");
      //   wbr && wbr.postMessage({"target": $(this).attr("data-target"), "command": "maximize"}, "*");
      //   currentLevel.trigger("click");
      //   break;
      case 45: // Insert - next top left
        wtl && wtl.postMessage({"target": "wtl", "command": "nextStranger"}, "*");
        break;
      case 36: // Home - next top right
        wtr && wtr.postMessage({"target": "wtr", "command": "nextStranger"}, "*");
        break;
      case 46: // Delete - next bottom left
        wbl && wbl.postMessage({"target": "wbl", "command": "nextStranger"}, "*");
        break;
      case 35: // End - next bottom right
        wbr && wbr.postMessage({"target": "wbr", "command": "nextStranger"}, "*");
        break;
      default: break;
    }
  };
  const handleSendMessage = (message, instant = false) => () => {
    const unicodeMessage = message.split('').map(char => chars.hasOwnProperty(char) ? utf32Decode(chars[char]) : char).join('');

    console.log('message:', unicode ? unicodeMessage : message);

    if (Boolean(wtl)) {
      wtl.postMessage({"target": 'wtl', "command": "sendMessage", "message": unicode ? unicodeMessage : message, "instant": instant}, "*");
    }
    if (Boolean(wtr)) {
      wtr.postMessage({"target": 'wtr', "command": "sendMessage", "message": unicode ? unicodeMessage : message, "instant": instant}, "*");
    }
    if (Boolean(wbl)) {
      wbl.postMessage({"target": 'wbl', "command": "sendMessage", "message": unicode ? unicodeMessage : message, "instant": instant}, "*");
    }
    if (Boolean(wbr)) {
      wbr.postMessage({"target": 'wbr', "command": "sendMessage", "message": unicode ? unicodeMessage : message, "instant": instant}, "*");
    }
  };

  if (game === null)
    return <Typography>Loading...</Typography>

  return (
    <Box sx={{flex: '500px 0 0', display: 'flex', flexDirection: 'column', backgroundColor: 'white'}}>
      <Box sx={{display: 'flex', margin: '8px'}}>
        <Button variant='contained' size='small' color='secondary'
          onClick={() => {
            setGame(games[0]);
            setLevel(0);
            setCompleted([]);
            setExtraPoints(0);
          }}
        >New game</Button>
        <Typography variant='h5' align='center' sx={{flexGrow: 1}}>{game.name}</Typography>
        <Button ref={refNextBoard} variant='contained' size='small' color='success'
          disabled={game.stages.length === level + 1}
          onClick={() => {
            // if (level === 0) {
            //   handleSendMessage('If you can\'t see the dares, tell me and i\'ll type them for you in the chat!', true)();
            // }
            setLevel(Math.min(game.stages.length - 1, level + 1));
          }}
          onKeyUp={handleKeyUp()}
          onBlur={() => refNextBoard.current.focus()}
        >{level === 0 ? 'Start game' : 'Next board'}</Button>
      </Box>

      <Box className='game'>
        {game.stages.map((stage, i) => (
          <Box key={i} className='stage' sx={{marginLeft: i === 0 ? `-${level * 500}px` : 'initial', backgroundImage: `url('${stage.background}')`}}>
            <Typography className='title' align='center' sx={{fontSize: '30px', cursor: 'pointer'}}
              onClick={() => {
                const message = stage.dares.reduce((acc, dare, index) => {
                  if (typeof dare.points !== 'undefined' && !completed.includes(`${stage.name} - ${dare.dare}`)) {
                    return `${acc}\nDare #${index + 1}: ${dare.dare} - ${dare.points} points`;
                  } else {
                    return acc;
                  }
                }, `${stage.title} - Incomplete dares`);
                navigator.clipboard.writeText(message);
              }}
              onDoubleClick={() => {
                handleSendMessage(stage.title, true)();
                stage.dares.forEach((dare, index) => {
                  if (typeof dare.points !== 'undefined' && !completed.includes(`${stage.name} - ${dare.dare}`)) {
                    handleSendMessage(`${dare.dare} - ${dare.points} points`, true)();
                  }
                });
              }}
            >
              {stage.title}
            </Typography>
            <Box className='dares'>
              {stage.dares.map((dare, i) => (
                <Box key={i} className={`dare${completed.includes(`${stage.name} - ${dare.dare}`) ? '-completed' : ''}`}
                  sx={{
                    display: 'flex',
                    borderBottom: typeof dare.points === 'undefined' ? 'none' : '1px solid lightgray',
                  }}
                  onClick={
                    typeof dare.points === 'undefined'
                      ? null
                      : () => {
                          if (completed.includes(`${stage.name} - ${dare.dare}`)) {
                            setCompleted(completed.filter(item => item !== `${stage.name} - ${dare.dare}`));
                          } else {
                            setCompleted(completed.concat(`${stage.name} - ${dare.dare}`));
                          }
                        }
                  }
                >
                  <Typography sx={{flexGrow: 1, minHeight: '1em'}}
                    align={typeof dare.points === 'undefined' ? 'center' : 'left'}
                  >
                    {dare.dare}
                  </Typography>
                  {typeof dare.points !== 'undefined' &&
                    <Typography align='right' sx={{flex: '70px 0 0'}}>{dare.points}</Typography>
                  }
                </Box>
              ))}
            </Box>
            <Typography className='score' align='right'>{i === 0 ? `Top score: ${game.topScore}` : `Your score: ${getScore()} pts`}</Typography>
          </Box>
        ))}
      </Box>

      <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
        {games.map((item, i) => (
          <Button key={i} variant='contained' size='small' sx={{margin: '4px'}}
            color={game.name === item.name ? 'secondary' : 'inherit'}
            onClick={() => {
              setGame(games[i]);
              setLevel(1);
              setCompleted([]);
              setExtraPoints(0);
            }}
          >{item.name}</Button>
        ))}
      </Box>

      <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
        {game.stages.map((stage, i) => (
          <Button key={i} variant='contained' size='small' sx={{display: i === 0 ? 'none' : 'initial', margin: '4px'}}
            color={level === i
              ? 'warning'
              : stage.dares.filter(dare => Boolean(dare.points) && !completed.includes(`${stage.name} - ${dare.dare}`)).length === 0
                ? 'success'
                : 'primary'
          }
            onClick={() => setLevel(i)}
          >{stage.name}</Button>
        ))}
        <span style={{flexGrow: 1}} />
      </Box>

      <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
        <Box sx={{flexBasis: '22%', flexShrink: 0, flexGrow: 0, padding: '12px 0px', textAlign: 'center'}}>
          {wtl === null
            // ? <Button variant='outlined' size='large' color='primary' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} onClick={openWindow('wtl')}>Chatrandom</Button>
            ? <Button variant='outlined' size='large' color='primary' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} onClick={openWindow('wtl')}>Bazoocam</Button>
            : <Button variant='outlined' size='large' color='secondary' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} onClick={closeWindow('wtl')}>Close</Button>
          }
        </Box>
        <Box sx={{flexBasis: '22%', flexShrink: 0, flexGrow: 0, padding: '12px 0px', textAlign: 'center'}}>
          <Button variant='outlined' size='large' color='warning' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} disabled={wtl === null} onClick={skipStranger('wtl')}>Next</Button>
        </Box>

        <Box sx={{flexGrow: 1}} />

        <Box sx={{flexBasis: '22%', flexShrink: 0, flexGrow: 0, padding: '12px 0px', textAlign: 'center'}}>
          <Button variant='outlined' size='large' color='warning' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} disabled={wtr === null} onClick={skipStranger('wtr')}>Next</Button>
        </Box>
        <Box sx={{flexBasis: '22%', flexShrink: 0, flexGrow: 0, padding: '12px 0px', textAlign: 'center'}}>
          {wtr === null
            ? <Button variant='outlined' size='large' color='primary' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} onClick={openWindow('wtr')}>Flingster</Button>
            // ? <Button variant='outlined' size='large' color='primary' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} onClick={openWindow('wtr')}>Bazoocam</Button>
            : <Button variant='outlined' size='large' color='secondary' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} onClick={closeWindow('wtr')}>Close</Button>
          }
        </Box>

        <Box sx={{flexBasis: '22%', flexShrink: 0, flexGrow: 0, padding: '12px 0px', textAlign: 'center'}}>
          {wbl === null
            ? <Button variant='outlined' size='large' color='primary' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} onClick={openWindow('wbl')}>Emerald</Button>
            // ? <Button variant='outlined' size='large' color='primary' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} onClick={openWindow('wbl')}>Bazoocam</Button>
            : <Button variant='outlined' size='large' color='secondary' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} onClick={closeWindow('wbl')}>Close</Button>
          }
        </Box>
        <Box sx={{flexBasis: '22%', flexShrink: 0, flexGrow: 0, padding: '12px 0px', textAlign: 'center'}}>
          <Button variant='outlined' size='large' color='warning' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} disabled={wbl === null} onClick={skipStranger('wbl')}>Next</Button>
        </Box>

        <Box sx={{flexGrow: 1}} />

        <Box sx={{flexBasis: '22%', flexShrink: 0, flexGrow: 0, padding: '12px 0px', textAlign: 'center'}}>
          <Button variant='outlined' size='large' color='warning' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} disabled={wbr === null} onClick={skipStranger('wbr')}>Next</Button>
        </Box>
        <Box sx={{flexBasis: '22%', flexShrink: 0, flexGrow: 0, padding: '12px 0px', textAlign: 'center'}}>
          {wbr === null
            ? <Button variant='outlined' size='large' color='primary' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} onClick={openWindow('wbr')}>Emerald</Button>
            : <Button variant='outlined' size='large' color='secondary' sx={{height: '72px', width: 'calc(100% - 10px)', padding: '8px'}} onClick={closeWindow('wbr')}>Close</Button>
          }
        </Box>
      </Box>

      <Box>
        {/*getOpenedWindows() === 1 &&*/ game.stages[level].messages.map((message, i) => (
          <Tooltip key={i} arrow disableInteractive followCursor title={message}>
            <Chip variant='outlined' size='small' color='info' sx={{display: 'inline-flex', maxWidth: '155px' /*490px*/, m: .5}}
              label={message}
              onClick={handleSendMessage(message)}
            />
          </Tooltip>
        ))}
      </Box>

      <Box sx={{flexGrow: 1}} />

      <Box sx={{display: 'flex', justifyContent: 'space-around', mb: 2}}>
        <Button variant='contained' color='info' disabled={level === 0} onClick={() => setExtraPoints(extraPoints + 1000)}>+1k points</Button>
        <Button variant='contained' color='info' disabled={level === 0} onClick={() => setExtraPoints(extraPoints + 2000)}>+2k points</Button>
        <Button variant='contained' color='info' disabled={level === 0} onClick={() => setExtraPoints(extraPoints + 3000)}>+3k points</Button>
        <IconButton color={unicode ? 'primary' : 'disabled'} onClick={() => setUnicode(!unicode)}><EmojiSymbolsIcon /></IconButton>
      </Box>
    </Box>
  );
}